import React from "react";
import styled from "styled-components";

const ContainerFig1TextFig1 = styled.div`
  //border: 2px solid red;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ContainerFig1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 5px;
`;

const Titlufig1 = styled.div`
  font-weight: 700;
  font-size: 17px;
  margin-bottom: 10px;
`;
const ImgAlcatuire = styled.img`
  width: 40%;
`;
//text fig.1
const TextFig1 = styled.div`
  font-weight: 600;
  margin-top: 5px;
  text-align: left;

  @media screen and (max-width: 1220px) {
    //font-size: 14px;
  }
`;

const DivFig1 = () => {
  return (
    <ContainerFig1TextFig1>
      <ContainerFig1>
        <Titlufig1>Detaliu de alcătuire sistem hidroizolant IZO-35</Titlufig1>
        <ImgAlcatuire src="/images/DescriereHidroizolatie/img-structura-izo-35.jpg" />
        <TextFig1>
          <div>1.Element care se hidroizolează.</div>
          <div>
            2.Suprafața pe care se dispune membrana hidroizolantă: stratul
            suport.
          </div>
          <div>
            3.(A)Strat cu rol de barieră de vapori:TOP CRETE PRIMER (dacă
            umiditatea stratului suport > 6%).
          </div>
          <div>
            4.(B)Adeziv:EURODEZ AP 61 sau EURODEZ AP 201 sau ADESILEX VZ
            (MAPEI).
          </div>
          <div>
            5.(C)Membrana hidroizolantă IZO-35, de lățime 1,2m sau 1,5m, suluri
            dispuse longitudinal podului.
          </div>
          <div>
            6.(C)Membrana hidroizolantă IZO-35,benzi de etanșare Bs=10-15cm,
            suprapunere membrane.
          </div>
          <div>
            7.(D)Protecție hidroizolație: TOP CRETE PRIMER sau EURODEZ AP 61 cu
            nisip cuarțos 0.3...1.0mm.
          </div>
        </TextFig1>
      </ContainerFig1>
      <div style={{ fontWeight: "700", textAlign: "center" }}>
        Fig. 1. Detaliu de alcătuire sistem hidroizolant IZO-35
      </div>
    </ContainerFig1TextFig1>
  );
};

export default DivFig1;
