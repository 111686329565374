import React from "react";
import styled from "styled-components";
const DivDescriereStraturi = styled.div`
  padding: 20px;
`;

const TextTipuriMembrane = () => {
  return (
    <DivDescriereStraturi>
      {/* -----------Paragraf A------------ */}
      <div style={{ display: "flex" }}>
        <div style={{ fontWeight: "700", marginRight: "5px" }}>A.</div>
        <div>
          <div style={{ fontWeight: "700" }}>
            Strat cu rol de barieră de vapori – TOP CRETE Primer
          </div>
          <div>
            Se aplică pe suprafaţa suport înainte de aplicarea stratului de
            adeziv. Este obligatoriu în cazul unei umidităţi a stratului suport
            de peste 6%, este complet impermeabil, acoperă fisurile din stratul
            suport, asigură o aderenţă superioară a adezivului (B), comparativ
            cu aderenţa adezivului aplicat pe stratul suport.
          </div>

          <div>
            <span style={{ fontWeight: "700" }}>TOP CRETE Primer</span> – face
            parte din gama produselor bicomponete pe bază de răşini
            poliuretanice, întăritor şi componenta solidă, fără solvenţi,
            nepoluant, neinflamabil.
          </div>

          <div>
            Componenta A (răsină) şi componenta B (întăritor), sunt ambalate în
            recipiente separate, cu raport de amestecare 1:1.
          </div>
          <div>Componenta B (EURODEZ), se răstoarnă peste componenta A. </div>
          <div>
            Amestecarea componentelor A şi B se face timp de 30 secunde, cu un
            malaxor de turaţie redusă (300 rotaţii/minut).
          </div>
          <div>
            Bicomponentul TOP CRETE Primer se aplică pe stratul suport imediat
            după realizarea amestecului şi se nivelează până la obţinerea unei
            pelicule uniforme care trebuie să acopere complet suprafaţa suport.
          </div>
          <div>
            Nu se lasă în recipientul de amestecare ci se întinde şi nivelează
            imediat pe suprafaţa suport, deoarece dacă rămâne neaplicat intră
            într-o reacţie exotermă şi devine spumant.
          </div>
          <div>
            Este fluid timp de circa 10 minute de la realizarea amestecului
            celor 2 componente.
          </div>
          <div>
            Imediat după aplicarea stratului de TOP CRETE Primer, se împrăştie
            cu metoda “în ploaie”, nisip cuarţos cu granulaţia 0,4-0,8mm.
          </div>
          <div>
            Nisipul cuarţos care nu s-a integrat în stratul TOP CRETE Primer, se
            recuperează cu mături, după care se îndepărtează nisipul rămas
            neaderent utilizând jet de aer comprimat.
          </div>
          <div>Consumul de TOP CRETE Primer este cca. 300...500 g/m2.</div>
          <div>
            Se livrează în recipiente în variantele: 1kg+1kg, 6kg+6kg,
            12kg+12kg.
          </div>
        </div>
      </div>
      {/* -----------Incheiere  paragraf A------------ */}

      {/* -----------Paragraf B------------ */}
      {/* <div style={{ display: "flex" }}> */}

      <div>
        <div>
          <span style={{ fontWeight: "700", marginRight: "5px" }}>B.</span>
          <span style={{ fontWeight: "700" }}>Strat de adeziv </span>– constă în
          una dintre următoarele variante de adezivi:
          <ul>
            <li>
              <div>
                <span style={{ fontWeight: "700" }}>EURODEZ AP 61 </span>– este
                un adeziv structural de reacţie, poliuretanic, fără solvenţi şi
                fără apă, bicomponent, cu amestec în proporţie de 6:1.
              </div>
              <div>
                Prezintă duritate ridicată, rezistenţă mecanică la medii chimice
                şi temperaturi.
              </div>
              <div>
                Componenta A (răsină) şi componenta B (întăritor), sunt ambalate
                în recipiente separate, cu raport de amestecare 6:1.{" "}
              </div>
              <div>
                Componenta B (EURODEZ), se răstoarnă peste componenta A.{" "}
              </div>
              <div>
                Amestecarea componentelor A şi B se face timp de 1 minut, cu un
                malaxor de turaţie redusă (300 rotaţii/minut).
              </div>
              <div>
                Se pune în aplicare imediat după realizarea amestecului, fiind
                fluid timp de circa 15-20 minute de la realizarea amestecului
                celor 2 componente, după care vâscozitatea amestecului creşte
                treptat.
              </div>
              <div>
                În cazul membranelor hidroizolante care au ţesătură din
                poliester EE80 la partea inferioară, se poate adăuga în amestec
                nisip cuarţos granulaţie 0,4-0,8mm în proporţia: (Nisip
                cuarţos)/(EURODEZ AP 61)=1,0:1,0.
              </div>
              <div>
                În cazul în care EURODEZ AP 61 se aplică peste TOP CRETE Primer,
                aplicarea se poate efectua imediat ce stratul TOP CRETE Primer
                nu se mai lipeşte pe deget şi se poate călca pe el fără să apară
                modificări de aspect ale zonei pe care se circulă (după
                recuperarea nisipului în exces şi îndepărtarea cu jet de aer
                comprimat a nisipului care nu a aderat la TOP CRETE Primer).
              </div>
              <div>
                Intervalul recomandat de aplicare a stratului de adeziv EURODEZ
                AP 61 pe suprafaţa tratată cu TOP CRETE Primer este în funcţie
                de temperatură, între 1...24 ore de la aplicarea TOP CRETE
                Primer, efectuându-se verificarea de la paragraful anterior şi
                verificarea unei umidităţi sub 6% măsurată la suprafaţă după
                aplicarea TOP CRETE Primer.
              </div>
              <div>
                Consumul de EURODEZ AP 61 utilizat sub membrana hidroizolantă
                este de cca. 1,5...2,5 kg/m2.
              </div>
              <div>
                Se livrează în recipiente în variantele: 6kg+1kg, 12kg+2kg,
                24kg+4kg.
              </div>
            </li>
            <li>
              <div>
                <span style={{ fontWeight: "700" }}>EURODEZ AP 201 </span>– este
                un adeziv structural de reacţie, poliuretanic, fără solvenţi şi
                fără apă, bicomponent, cu amestec în proporţie de 20:1, fiind
                mai elastic decât EURODEZ AP 61; Prezintă duritate ridicată,
                rezistenţă mecanică la medii chimice şi temperaturi.
              </div>
              <div>
                Componenta A (răsină) şi componenta B (întăritor), sunt ambalate
                în recipiente separate, cu raport de amestecare 20:1.
              </div>
              <div>
                Componenta B (EURODEZ), se răstoarnă peste componenta A.
              </div>
              <div>
                Amestecarea componentelor A şi B se face timp de 2 minute, cu un
                malaxor de turaţie redusă (300 rotaţii/minut).
              </div>
              <div>
                Se aplică imediat după realizarea amestecului, pe ambele
                suprafeţe care vor fi în contact, fiind fluid timp de circa 5-10
                minute de la realizarea amestecului celor 2 componente, după
                care vâscozitatea amestecului creşte treptat.
              </div>
              <div>
                În cazul în care EURODEZ AP 201 se aplică peste TOP CRETE
                Primer, aplicarea se poate efectua imediat ce stratul TOP CRETE
                Primer nu se mai lipeşte pe deget şi se poate călca pe el fără
                să apară modificări de aspect ale zonei pe care se circulă (după
                recuperarea nisipului în exces şi îndepărtarea cu jet de aer
                comprimat a nisipului care nu a aderat la TOP CRETE Primer).
              </div>
              <div>
                Intervalul recomandat de aplicare a stratului de adeziv EURODEZ
                AP 201 pe suprafaţa tratată cu TOP CRETE Primer este în funcţie
                de temperatură, între 1...24 ore de la aplicarea TOP CRETE
                Primer, efectuându-se verificarea de la paragraful anterior şi
                verificarea umidităţi sub 6%.
              </div>
              <div>
                Se aşteaptă circa 10 minute, până când stratul de adeziv nu se
                mai prinde de deget, după care se efectuează contactul dintre
                cele 2 suprafeţe pe care s-a aplicat EURODEZ AP 201.
              </div>
              <div>
                Consumul de EURODEZ AP 201 utilizat sub membrana hidroizolantă
                este de cca. 1,5...2,5 kg/m2.
              </div>
              <div>
                Se livrează în recipiente în variantele: 0,5kg+10kg, 1kg+20kg.
              </div>
            </li>
            <li>
              <div>
                <span style={{ fontWeight: "700" }}>ADESILEX VZ MAPEI </span>–
                este un adeziv de contact, policloroprenic cu solvent, cu
                aplicare pe stratul suport din beton, cu priza imediată.
              </div>
              <div>
                Adezivul monocomponent ADESILEX VZ (producător MAPEI), se va
                întinde pe ambele suprafeţe care vor fi în contact, adică pe
                stratul suport dar şi pe partea membranei hidroizolante care se
                va lipi de stratul suport, după care se aşteaptă circa 10 minute
                înainte de realizarea contactului dintre stratul suport şi
                membrana hidroizolantă, sau cât timp este necesar pentru ca din
                adeziv să se evapore solventul (adezivul trebuie să nu se mai
                prindă de deget la contactul direct);
              </div>
              <div>
                Consumul de ADESILEX VZ MAPEI utilizat sub membrana
                hidroizolantă este cca. 1,5...2,5 kg/m2.
              </div>
              <div>
                Se livrează în recipiente metalice în variantele: 1kg, 5kg, 10
                kg.
              </div>
            </li>
          </ul>
        </div>
      </div>
      {/* </div> */}
      {/* -----------Incheiere  paragraf B------------ */}

      {/* -------------------PARAGRAF C------------ */}
      <div>
        <div>
          <span style={{ fontWeight: "700" }}>
            C. Strat hidroizolant – Membrana hidroizolantă IZO-35
          </span>
          - se livrează în suluri cu lungimea de 8...15m sau alte lungimi la
          solicitarea beneficiarului, având lăţimea de 1,00...1,50m, care se
          utilizează individual, în funcție de prescripţiile proiectelor
          tehnice.
        </div>
        <div>
          Membranele hidroizolante tip IZO-35, sunt compuse din ţesătură din
          poliester şi masă pe bază de cauciuc, impermeabilizantă.
        </div>
        <div>
          Membranele hidroizolante utilizate diferă în funcţie de grosime
          (2,0mm, 2,5mm, 3,0mm), de tipul ţesăturilor de poliester utilizate
          (PE-IC-50 şi/sau EE80) şi de poziţia ţesăturii de poliester cu rol de
          armare a membranei (care se dispune în mod obligatoriu pe cele 2
          suprafeţe ale membranei, dar există şi varianta în care se dispune
          ţesătură de poliester EE80 suplimentară, la mijlocul membranei).{" "}
        </div>
      </div>

      {/* -----------Incheiere  paragraf C------------ */}

      {/* -------------------PARAGRAF D------------ */}

      <div>
        <div>
          <span style={{ fontWeight: "700" }}>
            D. Strat de protecţie a membranei hidroizolante
          </span>
          - se realizează amestecul în acelaşi mod ca şi la stratul de adeziv
          EURODEZ AP 61 sau TOP CRETE Primer, (utilizat la lipirea membranei
          hidroizolante pe stratul suport sau la stratul cu rol de amorsă şi
          barieră de vapori) cu precizarea că în amestecul de bicomponent se
          poate adăuga nisip cuarţos cu granulaţia 0,4...0,8mm, având (nisip
          cuarţos) : (adeziv) în proporţia 0,5:1,0.
        </div>
        <div>
          Imediat după aplicarea pe suprafaţa superioară a hidroizolaţiei a
          adezivului Eurodez AP 61 sau TOP CRETE Primer, se presară prin metoda
          “în ploaie”, nisip cuarţos 0,4mm...0,8mm.
        </div>
        <div>
          Înainte de dispunerea straturilor asfaltice, se îndepărtează de pe
          stratul de protecţie a hidroizolaţiei, excesul de nisip care nu a
          aderat la stratul suport, utilizându-se aer comprimat.
        </div>
        <div>
          Acest strat este obligatoriu în cazul membranelor hidroizolante care
          au la partea superioară ţesătură de poliester EE80 şi în cazul
          membranelor dispuse pe poduri solicitate la un trafic intens (se va
          utiliza în cazul podurilor amplasate pe autostrăzi, drumuri europene,
          drumuri naţionale, drumuri judeţene intens circulate, centuri de
          ocolire sau drumuri destinate traficului greu). Peste stratul de
          protecţie al hidroizolaţiei nu se va circula cu şenile metalice.
        </div>
        <div>
          Consumul de EURODEZ AP 61 utilizat în protecţia membranei
          hidroizolante cca. 1...2 kg/m2, iar în cazul utilizării TOP CRETE
          Primer, consumul va fi de 0,2...0,4 kg/m2.
        </div>
      </div>
      {/* -----------Incheiere  paragraf D------------ */}
    </DivDescriereStraturi>
  );
};

export default TextTipuriMembrane;
