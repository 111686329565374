import React from "react";
import styled from "styled-components";

const ContainerObservatii = styled.div`
  // /border: 2px solid red;
  text-align: center;
  width: 60%;
  margin-top: 20px;
  font-weight: 500;
  @media screen and (max-width: 1000px) {
    width: 95%;
  }
`;

const DivObservatiiLucrareSingulara = ({ lucrare }) => {
  return (
    <ContainerObservatii>
      {lucrare.observatii.map((item) => {
        return <div key={item.id}>{item.observatie}</div>;
      })}
    </ContainerObservatii>
  );
};

export default DivObservatiiLucrareSingulara;
