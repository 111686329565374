import React from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "./PaginaHome/HomePage";
import DescriereHidroizolatie from "./PaginaDescriereIzo/DescriereHidroizolatie";
import PortofoliuLucrari from "./PaginaPortofoliu/PortofoliuLucrari";
import TesteLaborator from "./PaginaTesteLaborator/TesteLaborator";
import ConformitateSiAgrement from "./ConformitateSiAgrement/ConformitateSiAgrement";

const Views = ({}) => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />

      <Route
        path="/descriere-sistem-hidroizolant"
        element={<DescriereHidroizolatie />}
      />
      <Route path="/portofoliu-lucrari" element={<PortofoliuLucrari />} />
      <Route path="/teste-laborator" element={<TesteLaborator />} />
      <Route
        path="//declaratie-conformitate-agrement"
        element={<ConformitateSiAgrement />}
      />
    </Routes>
  );
};

export default Views;
