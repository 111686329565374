import React from "react";
import styled from "styled-components";

const LogoContainer = styled.div``;

const LogoTomizo = ({ width, position, left }) => {
  return (
    <LogoContainer
      style={{
        position: `${position}`,
        left: `${left}`,
      }}
    >
      <img
        src="/images/LogoImg/logo-tomizo.png"
        style={{ width: `${width}` }}
      />
    </LogoContainer>
  );
};

export default LogoTomizo;
