import React, { useState } from "react";
import styled from "styled-components";
import { imaginiAgrement } from "../Data/imaginiAgrement";
import ImageGridWithModal from "../ImageGridWithModal";
import * as variabileDesign from "../VariabileDesign/variabileDesign.js";

const Wrap = styled.div`
  //border: 2px solid red;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;
const ContentDiv = styled.div`
  width: 80%;
  //border: 2px solid blue;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
`;
const DivAgrement = styled.div`
  //border: 2px solid green;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: red;
`;
const ContentImgSingulare = styled.div`
  width: 80%;
  //border: 2px solid green;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;
const ButonAgrement = styled.button`
  background-color: ${variabileDesign.culoareButoane};
  border-radius: 10px;
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  font-size: 16px;
  margin: 4px 2px;
  opacity: 0.6;
  transition: 0.3s;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
  &:hover {
    opacity: 1;
  }
`;
const ButonInchidereAgrement = styled.button`
  background-color: ${variabileDesign.culoareButoane};
  border-radius: 10px;
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  font-size: 16px;
  margin: 4px 2px;
  opacity: 0.6;
  transition: 0.3s;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
  &:hover {
    opacity: 1;
  }
`;
const DivAgrementIntreg = styled.div`
  //border: 2px solid red;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;
const DivConformitate = styled.div`
  //border: 2px solid green;
  color: ${variabileDesign.culoareTextPrimar};
`;
const ImgAgrement = styled.img`
  width: 200px;
  border: 1px solid black;
`;
const ImgConformitate = styled.img`
  width: 200px;
  border: 1px solid black;
`;
const ConformitateSiAgrement = () => {
  const [isVisibleAgrement, setIsVisibleAgrement] = useState(false);
  return (
    <Wrap>
      <ContentDiv>
        {/* ---------Creare pdf si descarcare------------ */}
        <ContentImgSingulare>
          <DivAgrement>
            <ButonAgrement onClick={() => setIsVisibleAgrement(true)}>
              Vizualizează agrementul
            </ButonAgrement>

            <ImgAgrement
              src="/images/ConformitateSiAgrement/prima-pagina-agrement.jpg"
              alt="img-conformitate"
            />
          </DivAgrement>
          <DivConformitate>
            <div style={{ padding: "5px" }}>Declarație de conformitate:</div>
            <ImgConformitate src="/images/ConformitateSiAgrement/declaratie-conformitate.jpg" />
          </DivConformitate>
        </ContentImgSingulare>

        {isVisibleAgrement && (
          <DivAgrementIntreg>
            <ButonInchidereAgrement onClick={() => setIsVisibleAgrement(false)}>
              Închide agrementul
            </ButonInchidereAgrement>
            <ImageGridWithModal img={imaginiAgrement} />
          </DivAgrementIntreg>
        )}
      </ContentDiv>
    </Wrap>
  );
};

export default ConformitateSiAgrement;
