import React, { useEffect, useState } from "react";
import "./myCarousel.css";
import { ArrowBigLeft, ArrowBigRight } from "lucide-react";
import styled from "styled-components";

const SliderButton = styled.button`
  width: 60px;
  height: 60px;
  all: unset;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 1rem;
  cursor: pointer;
  transition: background-color 100ms ease-in-out;

  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;

const MyCarousel = ({ images }) => {
  const [imageIndex, setImageIndex] = useState(0);
  const [autoPlay, setAutoPlay] = useState(true);
  let timeOut = null;

  useEffect(() => {
    timeOut =
      autoPlay &&
      setTimeout(() => {
        showNextImage();
      }, 5500);
  });

  const showPrevImage = () => {
    setImageIndex((index) => {
      if (index == 0) return images.length - 1;
      else return index - 1;
    });
  };
  const showNextImage = () => {
    setImageIndex((index) => {
      if (index == images.length - 1) return 0;
      else return index + 1;
    });
  };

  return (
    <div
      className="carousel"
      onMouseEnter={() => {
        setAutoPlay(false);
        clearTimeout(timeOut);
      }}
      onMouseLeave={() => {
        setAutoPlay(true);
      }}
    >
      <div className="carousel-wrapper">
        {images.map((img, index) => {
          return (
            <div
              key={img.id}
              className={
                index == imageIndex ? "img-div img-div-active" : "img-div "
              }
            >
              <img className="carousel-img" src={img.src} alt="" />
            </div>
          );
        })}
      </div>
      <SliderButton style={{ left: "0" }} onClick={showPrevImage}>
        <ArrowBigLeft
          style={{
            stroke: "white",
            fill: "black",
            fontSize: "20px",
            width: "30px",
            height: "30px",
          }}
        />
      </SliderButton>
      <SliderButton style={{ right: "0" }} onClick={showNextImage}>
        <ArrowBigRight
          style={{
            stroke: "white",
            fill: "black",
            fontSize: "20px",
            width: "30px",
            height: "30px",
          }}
        />
      </SliderButton>
    </div>
  );
};

export default MyCarousel;
