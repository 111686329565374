import React from "react";
import styled from "styled-components";
import * as AiIcons from "react-icons/ai";
import { menuItems } from "./Data/menuItems";
import MenuItem from "./Header/MenuItem";

const FullPageWrap = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
`;

const SideWrap = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  width: 250px;
  height: 100vh;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
`;

const NavIconClose = styled.div`
  font-size: 40px;
  color: rgba(126, 211, 247, 255);
  margin-top: 10px;
  margin-left: auto;
  margin-right: 30px;
`;

const ContainerMenu = styled.div`
  //border: 2px solid red;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;
const DivMenu = styled.div`
  //border: 2px solid blue;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const SideNav = ({ sideNav, showSideNav }) => {
  return (
    <FullPageWrap sideNav={sideNav}>
      <SideWrap>
        {/* -------------X-ul de inchidere a side nav---------------- */}
        <NavIconClose
          onClick={() => {
            showSideNav();
          }}
        >
          <AiIcons.AiOutlineCloseSquare />
        </NavIconClose>
        <ContainerMenu>
          <DivMenu>
            {menuItems.map((menuItem) => {
              return (
                <MenuItem
                  key={menuItem.id}
                  menuItem={menuItem}
                  sideNav={sideNav}
                  showSideNav={showSideNav}
                />
              );
            })}
          </DivMenu>
        </ContainerMenu>
      </SideWrap>
    </FullPageWrap>
  );
};

export default SideNav;
