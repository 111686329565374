import React from "react";
import styled from "styled-components";
import { dateTesteLaborator } from "../Data/dateTesteLaborator";
import DivTextSiGalerieImg from "./DivTextSiGalerieImg";
import * as variabileDesign from "../VariabileDesign/variabileDesign.js";

const ContainerTesteLaborator = styled.div`
  //border: 2px solid red;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const TitluTesteLaborator = styled.div`
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  text-align: center;
  color: ${variabileDesign.culoareTextPrimar};
  @media screen and (max-width: 1000px) {
    width: 85%;
  }
`;

const ContentTesteLaborator = styled.div`
  //border: 2px solid green;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`;

const TesteLaborator = () => {
  return (
    <ContainerTesteLaborator>
      <div id="teste-laborator"></div>
      <TitluTesteLaborator>
        Imagini de la testele de laborator efectuate în cadrul rapoartelor de
        încercare
      </TitluTesteLaborator>
      <ContentTesteLaborator>
        {dateTesteLaborator.map((itemDateTesteLaborator) => {
          return (
            <DivTextSiGalerieImg
              itemDateTesteLaborator={itemDateTesteLaborator}
            />
          );
        })}
      </ContentTesteLaborator>
    </ContainerTesteLaborator>
  );
};

export default TesteLaborator;
