import React from "react";
import styled from "styled-components";

const LogoContainer = styled.div``;

const LogoMirada = ({ width, position, left }) => {
  return (
    <LogoContainer
      style={{
        position: `${position}`,
        left: `${left}`,
      }}
    >
      <img
        src="/images/LogoImg/logo-mirada.png"
        style={{ width: `${width}` }}
      />
    </LogoContainer>
  );
};

export default LogoMirada;
