import React from "react";
import styled from "styled-components";
import { HashLink as Link } from "react-router-hash-link";
import DivLiniiVerticale from "../DivLiniiVerticale/DivLiniiVerticale";
import * as variabileDesign from "../VariabileDesign/variabileDesign.js";

const NavLink = styled(Link)`
  padding: 5px 10px 5px 10px;
  cursor: pointer;
  color: white;
  text-decoration: none;
  font-weight: 400;
  font-size: 17px;
  @media screen and (max-width: 1650px) {
    font-size: 15px;
  }
  &:hover {
    color: #f7b876;
  }
`;
const MenuItemContainer = styled.div`
  border: 1px solid rgba(6, 31, 92, 255);
  width: 200px;
  background-color: ${variabileDesign.culoarePrimara};
  display: flex;
`;

const MenuItemName = styled.div`
  padding: 5px 5px 5px 15px;
  //border: 2px solid green;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const MenuItem = ({ menuItem, showSideNav }) => {
  return (
    <NavLink to={menuItem.path} onClick={showSideNav}>
      <MenuItemContainer>
        <DivLiniiVerticale
          height="100%"
          coloredLineWidth="5px"
          //culoareLinia0="rgb(181, 75, 55,0.2)"
          culoareLinia1="rgba(126,211,247,255)"
          culoareLinia2="rgba(0,174,239,255)"
          culoareLinia3="rgba(0,114,188,206)"
        />
        <MenuItemName> {menuItem.name}</MenuItemName>
      </MenuItemContainer>
    </NavLink>
  );
};

export default MenuItem;
