import React from "react";
import styled from "styled-components";
import { FaBars } from "react-icons/fa";

const Hambourger = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
`;

const HambourgerIcon = ({ showSideNav, color, fontSize, right }) => {
  return (
    <Hambourger
      style={{
        color: `${color}`,
        fontSize: `${fontSize}`,
        right: `${right}`,
      }}
    >
      <FaBars onClick={showSideNav} />
    </Hambourger>
  );
};

export default HambourgerIcon;
