import React from "react";
import styled from "styled-components";
import Mask from "./Mask";

const ContainerFundal = styled.div`
  //border: 2px solid blue;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  min-width: 100vh;
  min-height: 100vh;
  z-index: -1;
`;
//Masca neagra are z-index -2
const ImgFundal = styled.img`
  //border: 2px solid red;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -3;
`;

const ImagineFundal = () => {
  return (
    <ContainerFundal>
      <Mask />
      <ImgFundal src="/images/ImgFundal/3.png" />
    </ContainerFundal>
  );
};

export default ImagineFundal;
