export const dateTesteLaborator = [
  {
    id: "1",
    paragrafeText: [
      {
        id: "1",
        text: "Imagini relevante de la testele de laborator realizate pentru IZO-35 PE-00-PE ATJ 2,0, membrana hidroizolantă cu cea mai mică grosime (2mm) şi cea mai mică rezistenţă (fără ţesătură poliester EE80).",
      },
      {
        id: "2",
        text: "Epruveta de 50,82mm lăţime, cu grosimea de 1,94mm, s-a deformat de la o lungime iniţială de 10cm între bacurile de fixare, până la 55cm între bacurile de fixare, forţa înregistrată la rupere fiind 1,05KN.",
      },
    ],
    galleryImg: [
      {
        id: "1",
        src: "/images/TesteDeLaborator/lab-a1.jpg",
      },
      {
        id: "2",
        src: "/images/TesteDeLaborator/lab-a2.jpg",
      },
      {
        id: "3",
        src: "/images/TesteDeLaborator/lab-a3.jpg",
      },
      {
        id: "4",
        src: "/images/TesteDeLaborator/lab-a4.jpg",
      },
      {
        id: "5",
        src: "/images/TesteDeLaborator/lab-a5.jpg",
      },
      {
        id: "6",
        src: "/images/TesteDeLaborator/lab-a6.jpg",
      },
    ],
  },
  {
    id: "2",
    paragrafeText: [
      {
        id: "1",
        text: "Imagini relevante de la testele de laborator realizate pentru IZO-35 EE-00-EE ATJ 2,0, membrana hidroizolantă cu cea mai mică grosime (2mm) şi rezistenţă mare (cu 2 ţesături din poliester EE80).",
      },
      {
        id: "2",
        text: "Epruveta de 50,56mm lăţime, cu grosimea de 1,90mm, a înregistrat o forţa la rupere de 6 KN.",
      },
    ],
    galleryImg: [
      {
        id: "1",
        src: "/images/TesteDeLaborator/lab-b1.png",
      },
      {
        id: "2",
        src: "/images/TesteDeLaborator/lab-b2.png",
      },
      {
        id: "3",
        src: "/images/TesteDeLaborator/lab-b3.png",
      },
    ],
  },
  {
    id: "3",
    paragrafeText: [
      {
        id: "1",
        text: "Imagini relevante de la testele de laborator realizate pentru IZO-35 EE-00-PE ATJ 2,0, membrana hidroizolantă cu cea mai mică grosime (2mm) şi rezistenţă medie (cu o ţesătură poliester EE80).",
      },
      {
        id: "2",
        text: "După ruperea ţesăturii de poliester EE80 la 2,778 KN, membrana a fost ulterior pusă din nou în presă şi a continuat să se alungească până s-a atins cursa maximă a presei şi a trebuit oprit testul la 0,93KN.",
      },
      {
        id: "3",
        text: "Membrane hidroizolante IZO-35, care au trecut testul de rezistenţă chiar şi după rupere ţesătură EE80.",
      },
    ],
    galleryImg: [
      {
        id: "1",
        src: "/images/TesteDeLaborator/lab-c1.jpg",
      },
      {
        id: "2",
        src: "/images/TesteDeLaborator/lab-c2.jpg",
      },
      {
        id: "3",
        src: "/images/TesteDeLaborator/lab-c3.jpg",
      },
      {
        id: "4",
        src: "/images/TesteDeLaborator/lab-c4.jpg",
      },
    ],
  },
  {
    id: "4",
    paragrafeText: [
      {
        id: "1",
        text: "Imagini relevante de la testele de laborator realizate pentru IZO-35 EE-00-PE ATJ 2,0.",
      },
      {
        id: "2",
        text: "Membrana hidroizolantă cu cea mai mică grosime (2mm), lipită cu adezivul EURODEZ AP 61, a înregistrat o forţa de aderenţă de 3,604 KN, (1,84 N/mm2), ruperea fiind în stratul din beton.",
      },
    ],
    galleryImg: [
      {
        id: "1",
        src: "/images/TesteDeLaborator/lab-d1.jpg",
      },
      {
        id: "2",
        src: "/images/TesteDeLaborator/lab-d2.jpg",
      },
    ],
  },
];
