import React from "react";
import styled from "styled-components";
const DivTextImgTipuriMembrane = styled.div`
  //border: 2px solid blue;
  margin-top: 40px;
  display: flex;
  align-items: center;
  padding: 20px;
  @media screen and (max-width: 1220px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const DivImgTipuriDeMembrane = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1220px) {
  }
`;
//img membrane
const ImgMembrane = styled.img`
  width: 70%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;
//text fig 2
const TextFig2 = styled.div`
  font-weight: 700;
  margin-top: 5px;
  text-align: center;
  @media screen and (max-width: 1220px) {
    font-size: 14px;
  }
`;
//div text alaturat imaginii  membrane
const DivTextTipuriMembrane = styled.div`
  @media screen and (max-width: 1220px) {
    margin-top: 20px;
  }
`;

const DivFig2 = () => {
  return (
    <div>
      <DivTextImgTipuriMembrane>
        <DivImgTipuriDeMembrane>
          <ImgMembrane src="/images/DescriereHidroizolatie/img-macheta-tipuri-de-membrane.jpg" />
          <TextFig2 style={{ fontWeight: "700", textAlign: "center" }}>
            Fig. 2. Macheta IZO-35, tipuri de membrane şi adezivi
          </TextFig2>
        </DivImgTipuriDeMembrane>
        <DivTextTipuriMembrane>
          <ul>
            <li>
              pe coloana din stânga a imaginii din Fig. 2, în partea de jos este
              stratul suport din beton
            </li>
            <li>
              peste stratul suport din beton, se aplică amorsa TOP CRETE Primer
              (coloana din stânga, sus);
            </li>
            <li>
              peste stratul de amorsă, se aplică adezivii, (a doua coloană
              pornind dinspre stânga);
            </li>
            <li>
              peste stratul de adeziv, se aplică membranele hidroizolante, de
              culoare roşiatică cărămizie dacă membrana este armată cu ţesătura
              EE80, sau de culoare neagră cu inserţii albe, dacă membrana este
              din cauciuc armat cu material neţesut PE-IC, (a treia coloană
              pornind dinspre stânga);
            </li>
            <li>
              peste membranele hidroizolante, se aplică stratul de protecţie,
              TOP CRETE Primer sau EURODEZ AP61, peste care se presară nisip
              cuarţos 0,3...1,0mm (a patra coloană pornind dinspre stânga);
            </li>
          </ul>
        </DivTextTipuriMembrane>
      </DivTextImgTipuriMembrane>
    </div>
  );
};

export default DivFig2;
