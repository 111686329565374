import React from "react";
import styled from "styled-components";

const MaskDiv = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-width: 100vh;
  min-height: 100vh;
  z-index: -2;
`;

const Mask = () => {
  return <MaskDiv />;
};

export default Mask;
