import React, { useState } from "react";
import styled from "styled-components";
import Modal from "./Modal";

const ImgDiv = styled.div`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: white;
`;
const ContainerImageGrid = styled.div`
  --gap: 16px;
  --num-cols: 2;
  --row-height: auto;
  --row-width: auto;
  box-sizing: border-box;
  padding: var(--gap);
  display: grid;
  grid-template-columns: repeat(var(--num-cols), 1fr);
  grid-auto-rows: var(--row-height);
  gap: var(--gap);
  @media screen and (max-width: 1000px) {
    --num-cols: 2;
    --row-height: 200px;
  }
`;
const SingleImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 10px;
`;
const ImageGridWithModal = ({ img }) => {
  const [openModal, setOpenModal] = useState(false);
  const [imageId, setImageId] = useState(0);
  const [currentSrc, setCurrentSrc] = useState("");

  const handleImageClick = (id) => {
    setOpenModal(!openModal);
    setImageId(id);
    setCurrentSrc(img[id - 1].src);
  };

  return (
    <>
      <ContainerImageGrid>
        {img.map((item) => {
          return (
            <ImgDiv key={item.id}>
              <SingleImg
                src={item.src}
                alt="gallery"
                onClick={() => handleImageClick(item.id)}
              />
            </ImgDiv>
          );
        })}
        {openModal && (
          <Modal
            setOpenModal={setOpenModal}
            openModal={openModal}
            currentSrc={currentSrc}
            setCurrentSrc={setCurrentSrc}
            imageId={imageId}
            GalleryImg={img}
          />
        )}
      </ContainerImageGrid>
    </>
  );
};

export default ImageGridWithModal;
