import Radium, { StyleRoot } from "radium";
import "./App.css";
import React, { useState, useRef, useEffect } from "react";
import Footer from "./Components/Footer.js";
import Views from "./Components/Views";
import styled from "styled-components";
import HeaderMobil from "./Components/Header/HeaderMobil.js";
import HeaderDesktop from "./Components/Header/HeaderDesktop.js";
import * as variabileDesign from "./Components/VariabileDesign/variabileDesign.js";
import SideNav from "./Components/SideNav.js";
import VideoBackground from "./Components/VideoBackground/VideoBackground.js";
import HeaderDesktopAtScroll from "./Components/Header/HeaderDesktopAtScroll.js";
import ImagineFundal from "./Components/ImagineFundal.js";

const SiteContainer = styled.div`
  width: 100%;
  font-size: 15px;
  display: flex;
  justify-content: center;
  //position: absolute;
  //background-color: rgb(112, 128, 144);
`;
const FundalSiteContent = styled.div`
  width: 70%;
  margin-top: 30px;  
   background-color:rgba(255,255,255,0.7);  
  z-index=-98;]

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  @media screen and (min-width: 2000px) {
    width: 65%;
  }
  @media screen and (max-width: 1650px) {
    width: 80%;   
  }
  @media screen and (max-width: 1100px) {
    width: 90%;
    margin-top: 90px;
  }

`;
const SiteContent = styled.div`
  width: 100%;
`;

function App() {
  const [sideNav, setSideNav] = useState(false);
  const showSideNav = () => {
    setSideNav(!sideNav);
  };
  //--------Aparitia header-ului la SCROLL--------------------
  const [isVisible, setIsVisible] = useState(false);
  console.log(isVisible);
  useEffect(() => {
    window.addEventListener("scroll", listenToScrolFunc);
    return () => window.removeEventListener("scroll", listenToScrolFunc);
  }, []);

  const listenToScrolFunc = () => {
    let heightToHideFrom = window.innerHeight;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll > heightToHideFrom) {
      //isVisible && // to limit setting state only the first time
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  //----------------------------------------------------------
  return (
    <StyleRoot>
      <SiteContainer>
        {/* <VideoBackground /> */}
        <ImagineFundal />
        <FundalSiteContent>
          <SiteContent>
            <div id="home"></div>
            {/* ----Desktop Header---- */}
            <HeaderDesktop />
            {/* ----Desktop Header la SCROLL---- */}
            {isVisible && !sideNav && (
              <HeaderDesktopAtScroll
                sideNav={sideNav}
                showSideNav={showSideNav}
              />
            )}
            {/* ----Mobile Header---- */}
            <HeaderMobil sideNav={sideNav} showSideNav={showSideNav} />
            {sideNav && <SideNav showSideNav={showSideNav} />}
            <Views />
            <div id="contact"></div>
            <Footer />
          </SiteContent>
        </FundalSiteContent>
      </SiteContainer>
    </StyleRoot>
  );
}

export default App;
