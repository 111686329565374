import React from "react";
import styled from "styled-components";

const Line = styled.div``;

const VerticalLine = ({ height, width, backgroundColor }) => {
  return (
    <Line
      style={{ height: height, width: width, backgroundColor: backgroundColor }}
    />
  );
};

export default VerticalLine;
