import React from "react";
import styled from "styled-components";
import LinieOrizontala from "./LinieOrizontala";

const OrizontalLinesContainer = styled.div`
  width: 100%;
  //border: 2px solid red;
`;

const DivLiniiOrizontale = ({
  height,
  width,
  culoareLinia1,
  culoareLinia2,
  culoareLinia3,
}) => {
  return (
    <OrizontalLinesContainer>
      <LinieOrizontala
        height={`${height}`}
        width={`${width}`}
        backgroundColor={`${culoareLinia1}`}
      />

      <LinieOrizontala
        height={`${height}`}
        width={`${width}`}
        backgroundColor={`${culoareLinia2}`}
      />

      <LinieOrizontala
        height={`${height}`}
        width={`${width}`}
        backgroundColor={`${culoareLinia3}`}
      />
    </OrizontalLinesContainer>
  );
};
export default DivLiniiOrizontale;
