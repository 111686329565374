import React from "react";
import PerformanteIzoMobil from "./PerformanteIzoMobil";
import styled from "styled-components";
import * as variabileDesign from "../VariabileDesign/variabileDesign.js";

const ContainerPerformanteAvantajeIzo = styled.div`
  //border: 2px solid red;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //background-color: rgba(254, 231, 197, 0.8);
  font-size: 15px;
`;
const TitlePerformanteAvantajeIzo = styled.div`
  font-weight: 700;
  font-size: 25px;
  text-align: center;
  margin-bottom: 40px;
  color: ${variabileDesign.culoareTextPrimar};
`;
const ImgPerformanteDesktop = styled.img`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 80%;
  @media screen and (max-width: 1220px) {
    display: none;
  }
`;

const TextPerformanteAvantajeIzo = styled.div`
  //border: 2px solid yellow;
  margin-top: 30px;
  padding: 20px;
`;

const PerformanteAvantajeIzo = () => {
  return (
    <ContainerPerformanteAvantajeIzo>
      <TitlePerformanteAvantajeIzo>
        Performanţele şi avantajele utilizării IZO-35
      </TitlePerformanteAvantajeIzo>
      <ImgPerformanteDesktop src="/images/PerformanteAvantajeIzo/img-performante-izo-35.jpg" />
      <PerformanteIzoMobil />
      <TextPerformanteAvantajeIzo>
        <div>
          <div>
            <span style={{ fontWeight: "600" }}>
              Sistemul hidroizolant IZO-35
            </span>
            , a fost proiectat pentru a asigura o comportare a membranei
            hidroizolante fără fisuri, în următoarele condiţii:
          </div>
          <ul>
            <li>Intervalul de temperatură -35⁰...+190⁰</li>
            <li>Durata de viaţă estimată de minim 20 de ani</li>
            <li>
              Alungirea maximă înregistrată la teste 31%...573% (funcţie de
              alcătuire)
            </li>
            <li>
              Forţa de rupere înregistrată la teste 0,97KN...9,7KN (funcţie de
              alcătuire)
            </li>
            <li>
              Aderenţa sistemului hidroizolant la stratul suport Frupere ≤
              3,604KN (proba cu Ø 50mm)
            </li>
            <li>
              Absorbţia de apă a sistemului hidroizolant între 0,00% ... 0,10%
            </li>
          </ul>
        </div>
        <div>
          Forţele de rupere au depăşit în anumite cazuri, valori de peste 10 ori
          mai mari decât valoarea minimă admisă de norme, 0,8KN pentru proba de
          lăţime 50mm.
        </div>
        <div>
          Menţionăm că alungirile la rupere cu valori între 31% ... 53%, se
          referă la momentul de cedare a ţesăturii din poliester EE80 cu rol de
          armare a membranei şi nu reprezintă cedarea stratului din cauciuc.
          Prin urmare, la ruperea ţesăturii din poliester, stratul din caciuc
          rămâne în continuare fără fisuri şi impermeabil, până la alungiri cu
          valori între 100% ... 573%, iar valoarea minimă admisă de norme este
          40%.
        </div>
        <div>
          Au existat probe care au avut absorbţia de apă 0,00, măsurată după 24
          de ore de imersare în apă, la testele de laborator efectuate le INCERC
          Cluj-Napoca.
        </div>
        <div>
          Adezivii AP61 şi Top Crete Primer, au prezentat valori de aderenţă la
          beton, superioare rezistenţelor la întindere ale betonului C35/45,
          ceea ce înseamnă că ruperea s-a produs în beton şi nu în stratul de
          adeziv sau de membrană izolantă.
        </div>
      </TextPerformanteAvantajeIzo>
    </ContainerPerformanteAvantajeIzo>
  );
};

export default PerformanteAvantajeIzo;
