import React from "react";
import styled from "styled-components";
import VerticalLine from "./VerticalLine";

const VerticalLinesContainer = styled.div`
  display: flex;
`;

const DivLiniiVerticale = ({
  height,
  coloredLineWidth,
  culoareLinia0,
  culoareLinia1,
  culoareLinia2,
  culoareLinia3,
}) => {
  return (
    <VerticalLinesContainer>
      {/* <VerticalLine
        height={`${height}`}
        width={`${coloredLineWidth}`}
        backgroundColor={`${culoareLinia0}`}
      /> */}
      <VerticalLine
        height={`${height}`}
        width={`${coloredLineWidth}`}
        backgroundColor={`${culoareLinia1}`}
      />

      <VerticalLine
        height={`${height}`}
        width={`${coloredLineWidth}`}
        backgroundColor={`${culoareLinia2}`}
      />

      <VerticalLine
        height={`${height}`}
        width={`${coloredLineWidth}`}
        backgroundColor={`${culoareLinia3}`}
      />
    </VerticalLinesContainer>
  );
};

export default DivLiniiVerticale;
