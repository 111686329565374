import React, { useState } from "react";
import styled from "styled-components";
import * as AiIcons from "react-icons/ai";
import * as GiIcons from "react-icons/gi";
//import { GalleryImg } from "../Data/GalleryImg";
import { Row, Col } from "react-bootstrap";

const ModalBackground = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ModalWrap = styled.div`
  width: 70vw;
  height: 80vh;

  @media (max-width: 1000px) {
    width: 80vw;
    height: 60vh;
  }
`;
const ModalContent = styled(Row)`
  width: 70vw;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1000px) {
    width: 90vw;
    height: 70vh;
  }
`;
const CloseIcon = styled.div`
  font-size: 35px;
  position: absolute;
  top: 2px;
  left: 12px;
  color: black;
`;
const PreviousIcon = styled.div`
  font-size: 35px;
  color: black;
  position: absolute;
  top: 50%;
  left: 22px;
  transform: translateY(-50%);
`;
const NextIcon = styled.div`
  font-size: 35px;
  color: black;
  position: absolute;
  top: 50%;
  right: 22px;
  transform: translateY(-50%);
`;

const Modal = ({
  openModal,
  setOpenModal,
  currentSrc,
  setCurrentSrc,
  imageId,
  GalleryImg,
}) => {
  const [currentId, setCurrentId] = useState(imageId);

  const handleClickPrevious = (currentId) => {
    if (currentId !== 1) {
      setCurrentSrc(GalleryImg[parseInt(currentId) - 2].src);
      setCurrentId(parseInt(currentId) - 1);
    } else {
      setCurrentSrc(GalleryImg[parseInt(GalleryImg.length) - 1].src);
      setCurrentId(GalleryImg.length);
    }
  };

  const handleClickNext = (currentId) => {
    if (currentId !== GalleryImg.length) {
      setCurrentSrc(GalleryImg[currentId].src);

      setCurrentId(parseInt(currentId) + 1);
    } else {
      setCurrentSrc(GalleryImg[0].src);
      setCurrentId(1);
    }
  };

  return (
    <ModalBackground>
      <ModalWrap>
        <ModalContent>
          <Col className="position-relative p-0 ">
            <CloseIcon>
              <AiIcons.AiFillCloseCircle
                onClick={() => setOpenModal(!openModal)}
              />
            </CloseIcon>
            <PreviousIcon>
              <GiIcons.GiPreviousButton
                onClick={() => handleClickPrevious(currentId)}
              />
            </PreviousIcon>
            <NextIcon>
              <GiIcons.GiNextButton
                onClick={() => handleClickNext(currentId)}
              />
            </NextIcon>
            <img
              src={currentSrc}
              style={{
                width: "100%",
                maxHeight: "750px",
              }}
            />
          </Col>
        </ModalContent>
      </ModalWrap>
    </ModalBackground>
  );
};

export default Modal;
