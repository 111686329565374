import React from "react";
import styled from "styled-components";
import TextTipuriMembrane from "./TextTipuriMembrane";
import DivFig2 from "./DivFig2";

const DivTipuriMembrane = () => {
  return (
    <>
      <DivFig2 />
      <TextTipuriMembrane />
    </>
  );
};

export default DivTipuriMembrane;
