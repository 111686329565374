import React from "react";
import styled from "styled-components";
import videoFundal from "./videos/2.mp4";

const FundalVideo = styled.div`
  //border: 2px solid red;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-width: 100vh;
  min-height: 100vh;
  z-index: -99;
`;
const Mask = styled.div`
  //background-color: rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-width: 100vh;
  min-height: 100vh;
  z-index: 0;
`;
const Video = styled.video`
  width: 100%;
`;

const VideoBackground = () => {
  return (
    <FundalVideo>
      <Mask />
      <Video autoPlay muted loop>
        <source src={videoFundal} />
      </Video>
    </FundalVideo>
  );
};

export default VideoBackground;
