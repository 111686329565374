export const menuItems = [
  {
    id: "1",
    name: "Home",
    path: "/#home",
  },
  {
    id: "2",
    name: "Prezentare generală",
    path: "/#prezentare-generala",
  },
  {
    id: "3",
    name: "Domenii de utilizare",
    path: "/#domenii-utilizare",
  },
  {
    id: "4",
    name: " Performanțele și avantajele utilizării izo-35",
    path: "/#performante-si-avantaje",
  },
  {
    id: "5",
    name: "Descriere sistem hidroizolant",
    path: "/descriere-sistem-hidroizolant",
  },
  {
    id: "6",
    name: "Portofoliu de lucrări",
    path: "/portofoliu-lucrari",
  },
  {
    id: "7",
    name: "Teste de laborator",
    path: "/teste-laborator",
  },
  {
    id: "8",
    name: "Declarație de conformitate și agrement",
    path: "/declaratie-conformitate-agrement",
  },
  ,
  {
    id: "9",
    name: "Contact",
    path: "/#contact",
  },
];
