import React from "react";
import styled from "styled-components";
import PrezentareGenerala from "./PrezentareGenerala";
import DomeniiDeUtilizare from "./DomeniiDeUtilizare";
import PerformanteAvantajeIzo from "./PerformanteAvantajeIzo";

const HomeContainer = styled.div`
  //border: 2px solid red;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const HomeContent = styled.div`
  background-color: rgb(255, 255, 255, 0);
  //border: 2px solid blue;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1220px) {
    width: 95%;
  }
`;
const HomePage = () => {
  return (
    <HomeContainer>
      <HomeContent>
        <div id="prezentare-generala"></div>
        <PrezentareGenerala />
        <div id="domenii-utilizare"></div>
        <DomeniiDeUtilizare />
        <div id="performante-si-avantaje"></div>
        <PerformanteAvantajeIzo />
      </HomeContent>
    </HomeContainer>
  );
};

export default HomePage;
