import React from "react";
import styled from "styled-components";
import DivLiniiOrizontale from "./DivLiniiOrizontale/DivLiniiOrizontale";
import LogoMirada from "./Logos/LogoMirada";

const FooterContainer = styled.div`
  width: 100%;
  margin-top: 20px;
`;

const FooterContent = styled.div`
  background-color: rgb(30, 85, 130);
  width: 100%;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1000px) {
    height: auto;
    flex-direction: column;
  }
`;
const DivContact = styled.div`
  //border: 2px solid red;
  width: 60%;
  padding: 10px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-around;
  @media screen and (max-width: 1000px) {
    height: auto;
    flex-direction: column;
    width: 100%;
  }
`;
const DivContactMirada = styled.div`
  //border: 2px solid yellow;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 7px;
  @media screen and (max-width: 1000px) {
    width: 90%;
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <DivLiniiOrizontale
        height="3px"
        width="100%"
        culoareLinia1="rgba(126,211,247,255)"
        culoareLinia2="rgba(0,174,239,255)"
        culoareLinia3="rgba(0,114,188,206)"
      />
      <FooterContent>
        <DivContact>
          <LogoMirada width="100px" position="static" left="" />
          <DivContactMirada>
            <div
              style={{
                fontWeight: "700",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                fontSize: "18px",
              }}
            >
              S.C. Mirada Project S.R.L.
            </div>
            <div>
              <div>
                <span style={{ fontWeight: "600" }}>Sediu</span> : Cluj-Napoca,
                Str. Gh Dima nr 8. ap. 9
              </div>
              <div>
                <span style={{ fontWeight: "600" }}>Telefon</span> :
                +40748171704
              </div>
              <div>
                <span style={{ fontWeight: "600" }}>Email</span> :
                contact@mirada-project.ro
              </div>
            </div>
          </DivContactMirada>
        </DivContact>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;
