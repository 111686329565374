import React from "react";
import styled from "styled-components";
import DivAlcatuireIzo from "./DivAlcatuireIzo";
import DivTipuriMembrane from "./DivTipuriMembrane";
import * as variabileDesign from "../VariabileDesign/variabileDesign.js";

const ContainerDescriereHidroizolatie = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const ContentDescriereHidroizolatie = styled.div`
  //border: 1px solid green;
  width: 90%;
  background-color: white;
  @media screen and (max-width: 1000px) {
    width: 93%;
  }
`;
const TitluDescriereHidroizolatie = styled.div`
  font-weight: 700;
  font-size: 23px;
  text-align: center;
  margin-top: 60px;
  color: ${variabileDesign.culoareTextPrimar};
`;

const DescriereHidroizolatie = () => {
  return (
    <ContainerDescriereHidroizolatie>
      <ContentDescriereHidroizolatie>
        <TitluDescriereHidroizolatie>
          Descrierea sistemului hidroizolant
        </TitluDescriereHidroizolatie>
        <DivAlcatuireIzo />
        <DivTipuriMembrane />
      </ContentDescriereHidroizolatie>
    </ContainerDescriereHidroizolatie>
  );
};

export default DescriereHidroizolatie;
