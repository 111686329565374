import React from "react";
import styled from "styled-components";
import { menuItems } from "../Data/menuItems";
import MenuItem from "./MenuItem";
import DivTitlu from "./DivTitlu";
import MyCarousel from "../Carousel/MyCarousel";
import { imgSliderDataDesktop } from "../Data/imgSliderDataDesktop";

const ContainerHeader = styled.div`
  width: 100%;
  //border: 5px solid red;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1100px) {
    display: none;
  }
`;
const ContainerMenuSiPoza = styled.div`
  //border: 5px solid green;
  width: 100%;
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media screen and (min-width: 2000px) {
    width: 100%;
    height: 700px;
  }
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    height: auto;
  }
`;
const ContainerMenu = styled.div`
  //border: 2px solid blue;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const DivMenu = styled.div`
  width: 250px;
  //border: 2px solid blue;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const HeaderDesktop = () => {
  return (
    <ContainerHeader>
      <DivTitlu />
      <ContainerMenuSiPoza>
        <ContainerMenu>
          <DivMenu>
            {menuItems.map((menuItem) => {
              return <MenuItem key={menuItem.id} menuItem={menuItem} />;
            })}
          </DivMenu>
        </ContainerMenu>
        <MyCarousel images={imgSliderDataDesktop} />
      </ContainerMenuSiPoza>
    </ContainerHeader>
  );
};

export default HeaderDesktop;
