import React from "react";
import styled from "styled-components";
import Star from "../Star";

const ImgPerformanteMobil = styled.img`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 60%;
  @media screen and (max-width: 1220px) {
    width: 60%;
  }
`;
const DivTabelPerformanteMobil = styled.div`
  width: 95%;
  //border: 2px solid blue;
  @media screen and (min-width: 1220px) {
    display: none;
  }
`;
const DivDownloadImgPerformante = styled.div`
  font-weight: 700;
  font-size: 13px;
  color: red;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;
const Table = styled.table`
  margin-top: 20px;
  width: 100%;
`;
const Th = styled.th`
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  font-size: 13px;
`;
const Td = styled.td`
  border: 1px solid #dddddd;
  text-align: left;
  padding: 4px;
  font-size: 12px;
`;
const Tr = styled.tr`
  :nth-child(even) {
    background-color: rgba(254, 231, 197, 0.8);
  }
`;
const DivObservatiiPerformante = styled.div`
  font-size: 15px;
`;

const PerformanteIzoMobil = () => {
  return (
    <DivTabelPerformanteMobil>
      <DivDownloadImgPerformante>
        <ImgPerformanteMobil src="/images/PerformanteAvantajeIzo/img-performante-izo-35.jpg" />
        Imaginea cu performanțele de elasticitate și forțe de rupere se poate
        descărca de
        <a
          style={{ marginLeft: "7px" }}
          href="/images/PerformanteAvantajeIzo/img-performante-izo-35.jpg"
          download
        >
          AICI
        </a>
      </DivDownloadImgPerformante>
      {/* ---Table 1---- */}
      <Table>
        <thead>
          <Tr>
            <Th>Nr.</Th>
            <Th>Tip</Th>
            <Th>Țesături poliester utilizate</Th>
            <Th>Denumire membrană</Th>
          </Tr>
        </thead>
        <tbody>
          <Tr>
            <Td>1</Td>
            <Td>1.a</Td>
            <Td>superioară:PE-IC-50</Td>
            <Td>IZO-35 PE-00-PE ATJ 2,0</Td>
          </Tr>
          <Tr>
            <Td>2</Td>
            <Td>1.b</Td>
            <Td></Td>
            <Td>IZO-35 PE-00-PE ATJ 2,5</Td>
          </Tr>
          <Tr>
            <Td>3</Td>
            <Td>1.c</Td>
            <Td>inferioară:PE-IC-50</Td>
            <Td>IZO-35 PE-00-PE ATJ 3,0</Td>
          </Tr>
          <Tr>
            <Td>4</Td>
            <Td>2.a</Td>
            <Td>superioară:PE-IC-50</Td>
            <Td>IZO-35 EE-00-PE ATJ 2,0</Td>
          </Tr>
          <Tr>
            <Td>5</Td>
            <Td>2.b</Td>
            <Td></Td>
            <Td>IZO-35 EE-00-PE ATJ 2,5</Td>
          </Tr>
          <Tr>
            <Td>6</Td>
            <Td>2.c</Td>
            <Td>inferioară:EE80</Td>
            <Td>IZO-35 EE-00-PE ATJ 3,0</Td>
          </Tr>
          <Tr>
            <Td>7</Td>
            <Td>3.a</Td>
            <Td>superioară:EE80</Td>
            <Td>IZO-35 EE-00-EE ATJ 2,0</Td>
          </Tr>
          <Tr>
            <Td>8</Td>
            <Td>3.b</Td>
            <Td></Td>
            <Td>IZO-35 EE-00-EE ATJ 2,5</Td>
          </Tr>
          <Tr>
            <Td>9</Td>
            <Td>3.c</Td>
            <Td>inferioară:EE80</Td>
            <Td>IZO-35 EE-00-EE ATJ 3,0</Td>
          </Tr>
          <Tr>
            <Td>10</Td>
            <Td>4.a</Td>
            <Td>superioară:PE-IC-50</Td>
            <Td>-</Td>
          </Tr>
          <Tr>
            <Td>11</Td>
            <Td>4.b</Td>
            <Td>mijloc:EE80</Td>
            <Td>IZO-35 PE-EE-PE ATJ 2,5</Td>
          </Tr>
          <Tr>
            <Td>12</Td>
            <Td>4.c</Td>
            <Td>inferioară:PE-IC-50</Td>
            <Td>IZO-35 PE-EE-PE ATJ 3,0</Td>
          </Tr>
        </tbody>
      </Table>
      {/* ----Tabel 2---- */}
      <Table>
        <thead>
          <Tr>
            <Th>Nr.</Th>
            <Th>Grosime(mm)</Th>
            <Th>Greutate(kg/mp)</Th>
            <Th>Forța la rupere(KN)</Th>
          </Tr>
        </thead>
        <tbody>
          <Tr>
            <Td>1</Td>
            <Td>2,0</Td>
            <Td>2,43</Td>
            <Td>0,97</Td>
          </Tr>
          <Tr>
            <Td>2</Td>
            <Td>2,5</Td>
            <Td>3,01</Td>
            <Td>1,08</Td>
          </Tr>
          <Tr>
            <Td>3</Td>
            <Td>3,0</Td>
            <Td>3,59</Td>
            <Td>1,40</Td>
          </Tr>
          <Tr>
            <Td>4</Td>
            <Td>2,0</Td>
            <Td>2,24</Td>
            <Td>2,68</Td>
          </Tr>
          <Tr>
            <Td>5</Td>
            <Td>2,5</Td>
            <Td>2,82</Td>
            <Td>3,03</Td>
          </Tr>
          <Tr>
            <Td>6</Td>
            <Td>3,0</Td>
            <Td>3,40</Td>
            <Td>3,56</Td>
          </Tr>
          <Tr>
            <Td>7</Td>
            <Td>2,0</Td>
            <Td>2,17</Td>
            <Td>5,59</Td>
          </Tr>
          <Tr>
            <Td>8</Td>
            <Td>2,5</Td>
            <Td>2,75</Td>
            <Td>9,33</Td>
          </Tr>
          <Tr>
            <Td>9</Td>
            <Td>3,0</Td>
            <Td>3,32</Td>
            <Td>9,70</Td>
          </Tr>
          <Tr>
            <Td>10</Td>
            <Td>-</Td>
            <Td>-</Td>
            <Td>-</Td>
          </Tr>
          <Tr>
            <Td>11</Td>
            <Td>2,5</Td>
            <Td>2,86</Td>
            <Td>4,77</Td>
          </Tr>
          <Tr>
            <Td>12</Td>
            <Td>3,0</Td>
            <Td>4,15</Td>
            <Td>5,24</Td>
          </Tr>
        </tbody>
      </Table>
      {/* ----Tabel 3---- */}
      <Table>
        <thead>
          <Tr>
            <Th>Nr.</Th>
            <Th>Performanța la rupere</Th>
            <Th>Alungire la rupere(%)</Th>
            <Th>Performanța la alungire</Th>
          </Tr>
        </thead>
        <tbody>
          <Tr>
            <Td>1</Td>
            <Td>
              <Star />
            </Td>
            <Td>478</Td>
            <Td style={{ display: "flex" }}>
              <Star />
              <Star />
              <Star />
            </Td>
          </Tr>
          <Tr>
            <Td>2</Td>
            <Td>
              <Star />
            </Td>
            <Td>573</Td>
            <Td style={{ display: "flex" }}>
              <Star />
              <Star />
              <Star />
            </Td>
          </Tr>
          <Tr>
            <Td>3</Td>
            <Td>
              <Star />
            </Td>
            <Td>573</Td>
            <Td style={{ display: "flex" }}>
              <Star />
              <Star />
              <Star />
            </Td>
          </Tr>
          <Tr>
            <Td>4</Td>
            <Td style={{ display: "flex" }}>
              <Star />
              <Star />
            </Td>
            <Td>50</Td>
            <Td style={{ display: "flex" }}>
              <Star />
              <Star />
            </Td>
          </Tr>
          <Tr>
            <Td>5</Td>
            <Td style={{ display: "flex" }}>
              <Star />
              <Star />
            </Td>
            <Td>52</Td>
            <Td style={{ display: "flex" }}>
              <Star />
              <Star />
            </Td>
          </Tr>
          <Tr>
            <Td>6</Td>
            <Td style={{ display: "flex" }}>
              <Star />
              <Star />
            </Td>
            <Td>53</Td>
            <Td style={{ display: "flex" }}>
              <Star />
              <Star />
            </Td>
          </Tr>
          <Tr>
            <Td>7</Td>
            <Td style={{ display: "flex" }}>
              <Star />
              <Star />
              <Star />
            </Td>
            <Td>43</Td>
            <Td style={{ display: "flex" }}>
              <Star />
              <Star />
            </Td>
          </Tr>
          <Tr>
            <Td>8</Td>
            <Td style={{ display: "flex" }}>
              <Star />
              <Star />
              <Star />
            </Td>
            <Td>37</Td>
            <Td style={{ display: "flex" }}>
              <Star />
            </Td>
          </Tr>
          <Tr>
            <Td>9</Td>
            <Td style={{ display: "flex" }}>
              <Star />
              <Star />
              <Star />
            </Td>
            <Td>41</Td>
            <Td style={{ display: "flex" }}>
              <Star />
            </Td>
          </Tr>
          <Tr>
            <Td>10</Td>
            <Td>-</Td>
            <Td>-</Td>
            <Td>-</Td>
          </Tr>
          <Tr>
            <Td>11</Td>
            <Td style={{ display: "flex" }}>
              <Star />
              <Star />
            </Td>
            <Td>48</Td>
            <Td style={{ display: "flex" }}>
              <Star />
              <Star />
            </Td>
          </Tr>
          <Tr>
            <Td>12</Td>
            <Td style={{ display: "flex" }}>
              <Star />
              <Star />
              <Star />
            </Td>
            <Td>31</Td>
            <Td style={{ display: "flex" }}>
              <Star />
            </Td>
          </Tr>
        </tbody>
      </Table>
      <DivObservatiiPerformante>
        <div style={{ fontWeight: "700", marginTop: "10px" }}>Observații:</div>
        <ul>
          <li>
            Pentru performanța membranei în funcție de forța aplicată la rupere,
            s-a acordat:
            <div style={{ display: "flex" }}>
              <div> 1,0...2,5KN=</div>
              <Star />
            </div>
            <div style={{ display: "flex" }}>
              <div> 2,5...5,0KN=</div>
              <Star /> <Star />
            </div>
            <div style={{ display: "flex" }}>
              <div> 5,0...10,0KN=</div>
              <Star /> <Star /> <Star />
            </div>
          </li>
          <li>
            Menționăm că la membranele 1a,1b,1c, s-a oprit testul înainte de a
            se atinge forța de rupere, deoarece la presa din laboratorul de
            încercare s-a atins cursa maximă a presei fără ca epruvetele să se
            rupă;
          </li>
          <li>
            Menționăm că la membranele 2a și 3a, valorile forțelor din tabel se
            referă la momentul ruperii țesăturii din poliester EE80. După
            ruperea țesăturii din poliester, s-a constatat că epruveta testată
            poate prelua în continuare forțe de peste 0,8 KN fără să se rupă
            (partea de cauciuc nu s-a perforat);
          </li>
          <li>
            Pentru performanța membranei în funcție de alungirea la rupere, s-a
            acordat:
            <div style={{ display: "flex" }}>
              <div> 31...41%=</div>
              <Star />
            </div>
            <div style={{ display: "flex" }}>
              <div> 42...53%=</div>
              <Star /> <Star />
            </div>
            <div style={{ display: "flex" }}>
              <div> 54...573%=</div>
              <Star /> <Star /> <Star />
            </div>
          </li>
          <li>
            Menționăm că la membranele 1a, 1b, 1c, 2a, 3a, s-a oprit testul
            înainte de a se atinge forța și alungirea de rupere, deoarece la
            presa din laboratorul de încercare, s-a atins cusa maximă a presei
            fără ca partea din cauciuc a epruvetelor să se rupă. Prin urmare,
            partea de cauciuc a acestor membrane hidroizolante, rămîne
            impermeabilă chiar și după ruperea țesăturii din poliester și poate
            prelua în continuare forțe care depășesc 0,8 KN.
          </li>
        </ul>
      </DivObservatiiPerformante>
    </DivTabelPerformanteMobil>
  );
};

export default PerformanteIzoMobil;
