import React from "react";
import styled from "styled-components";

const Stea = styled.div`
  width: 17px;
  aspect-ratio: 1;
  background: #f8ca00;
  clip-path: polygon(
    50% 0,
    calc(50% * (1 + sin(0.4turn))) calc(50% * (1 - cos(0.4turn))),
    calc(50% * (1 - sin(0.2turn))) calc(50% * (1 - cos(0.2turn))),
    calc(50% * (1 + sin(0.2turn))) calc(50% * (1 - cos(0.2turn))),
    calc(50% * (1 - sin(0.4turn))) calc(50% * (1 - cos(0.4turn)))
  );
`;

const Star = () => {
  return <Stea />;
};

export default Star;
