import React from "react";
import styled from "styled-components";
import MyCarousel from "../Carousel/MyCarousel";
import { imgSliderDataDesktop } from "../Data/imgSliderDataDesktop";
import LogoTomizo from "../Logos/LogoTomizo.js";
import HambourgerIcon from "../HambourgerIcon";
import * as variabileDesign from "../VariabileDesign/variabileDesign.js";

const ContainerHeaderMobil = styled.div`
  //border: 2px solid blue;
  width: 100%;
  @media screen and (min-width: 1100px) {
    display: none;
  }
`;
const DivLogoHambourger = styled.div`
  //border: 2px solid blue;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  background-color: ${variabileDesign.culoarePrimara};
  height: 90px;
`;

const DivTitlu = styled.div`
  //border: 2px solid blue;
`;

const Titlu = styled.div`
  color: ${variabileDesign.culoareTextPrimar};
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  padding: 20px;
`;
const Subtitlu = styled.div`
  height: 30px;
  background-color: ${variabileDesign.culoareSecundara};
  width: 100%;
  color: white;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ContainerMyCarousel = styled.div`
  height: 500px;
`;
const HeaderMobil = ({ sideNav, showSideNav }) => {
  return (
    <ContainerHeaderMobil>
      {/* <DivTitlu /> */}

      <DivLogoHambourger>
        <LogoTomizo width="75px" position="absolute" left="75px" />
        {!sideNav && (
          <HambourgerIcon
            color="white"
            fontSize="35px"
            right="40px"
            showSideNav={showSideNav}
          />
        )}
      </DivLogoHambourger>
      <DivTitlu>
        <Titlu>HIDROIZOLAȚIE ULTRAPERFORMANTĂ IZO-35</Titlu>
      </DivTitlu>
      <Subtitlu>IMPERMEABILĂ ȘI FĂRĂ FISURI LA -35 DE GRADE</Subtitlu>
      <ContainerMyCarousel>
        <MyCarousel images={imgSliderDataDesktop} />
      </ContainerMyCarousel>
    </ContainerHeaderMobil>
  );
};

export default HeaderMobil;
