import React from "react";
import styled from "styled-components";
import * as variabileDesign from "../VariabileDesign/variabileDesign.js";
import LogoTomizo from "../Logos/LogoTomizo.js";
import DivLiniiOrizontale from "../DivLiniiOrizontale/DivLiniiOrizontale.js";

//cuprinde prima linie, al doilea div cu logo si titlu si a treia linie cu subtitlu
const ContainerTitlu = styled.div`
  //border: 1px solid red;
  width: 100%;
`;
const PrimaLinie = styled.div`
  width: 100%;
`;
const Linie = styled.div`
  width: 100%;
  height: 20px;
  background-color: ${(variabileDesign, variabileDesign.culoarePrimara)};
`;

const DivTitluSiLogo = styled.div`
  width: 100%;
  height: 120px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Titlu = styled.div`
  color: ${variabileDesign.culoareTextPrimar};
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  @media screen and (max-width: 1300px) {
    font-size: 27px;
  }
  @media screen and (max-width: 1300px) {
    font-size: 27px;
  }
  @media screen and (max-width: 1100px) {
    font-size: 25px;
  }
`;
const Subtitlu = styled.div`
  height: 30px;
  background-color: ${variabileDesign.culoareSecundara};
  width: 100%;
  color: white;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ImgWaterDroplet = styled.img`
  width: 100px;
`;

const DivTitlu = () => {
  return (
    <>
      <ContainerTitlu>
        <PrimaLinie>
          <DivLiniiOrizontale
            height="3px"
            width="100%"
            culoareLinia1="rgba(126,211,247,255)"
            culoareLinia2="rgba(0,174,239,255)"
            culoareLinia3="rgba(0,114,188,206)"
          />
          <Linie />
        </PrimaLinie>
        <DivTitluSiLogo>
          <LogoTomizo width="100px" position="absolute" left="75px" />
          {/* <ImgWaterDroplet src="/images/ImgDiverse/1.png"></ImgWaterDroplet> */}
          <Titlu>HIDROIZOLAȚIE ULTRAPERFORMANTĂ IZO-35</Titlu>
        </DivTitluSiLogo>
        <Subtitlu>IMPERMEABILĂ ȘI FĂRĂ FISURI LA -35 DE GRADE</Subtitlu>
      </ContainerTitlu>
    </>
  );
};

export default DivTitlu;
