export const lucrari = [
  {
    id: "1",
    denumire: "centura ocolire Beclean",
    titlu:
      "Pod construit în 2023, pe centura de ocolire Beclean, la care s-a aplicat sistemul hidroizolant IZO-35",
    observatii: [],
    galleryImg: [
      {
        id: "1",
        src: "/images/Portofoliu/PodCenturaOcolireBeclean/pod-beclean-1.jpg",
      },
      {
        id: "2",
        src: "/images/Portofoliu/PodCenturaOcolireBeclean/pod-beclean-2.jpg",
      },
      {
        id: "3",
        src: "/images/Portofoliu/PodCenturaOcolireBeclean/pod-beclean-3.jpg",
      },
      {
        id: "4",
        src: "/images/Portofoliu/PodCenturaOcolireBeclean/pod-beclean-4.jpg",
      },
    ],
  },
  {
    id: "2",
    denumire: "pod Lungesti",
    titlu:
      "Pod pe DN 75, la Lungeşti (la 28 km de Turda), fotografii realizate la 20 de ani de la hidroizolare.",
    observatii: [
      {
        id: "1",
        observatie:
          "Hidroizolat în 2001-2002,la care s-a utilizat compoziția de cauciuc din agrement.",
      },
    ],
    galleryImg: [
      {
        id: "1",
        src: "/images/Portofoliu/PodLungesti/pod-lungesti-1.jpg",
      },
      {
        id: "2",
        src: "/images/Portofoliu/PodLungesti/pod-lungesti-2.jpg",
      },
    ],
  },
  {
    id: "3",
    denumire: "pod Bunesti",
    titlu:
      "Pod pe DN 1C, la Buneşti (la 48 km de Cluj, E576), fotografii realizate la 20 de ani de la hidroizolare.",
    observatii: [
      {
        id: "1",
        observatie:
          "Hidroizolat în 2001-2002,la care s-a utilizat compoziția de cauciuc din agrement.",
      },
    ],
    galleryImg: [
      {
        id: "1",
        src: "/images/Portofoliu/PodBunesti/pod-bunesti-1.jpg",
      },
      {
        id: "2",
        src: "/images/Portofoliu/PodBunesti/pod-bunesti-2.jpg",
      },
    ],
  },
  {
    id: "4",
    denumire: "pod Perii Vadului",
    titlu:
      "Pod pe DN 1C, km 125, Perii Vadului, jud. Sălaj (E58), fotografii realizate la 20 de ani de la hidroizolare.",
    observatii: [
      {
        id: "1",
        observatie:
          "Hidroizolat în 2001-2002,la care s-a utilizat compoziția de cauciuc din agrement.",
      },
    ],
    galleryImg: [
      {
        id: "1",
        src: "/images/Portofoliu/PodPeriiVadului/pod-perii-vadului-1.jpg",
      },
      {
        id: "2",
        src: "/images/Portofoliu/PodPeriiVadului/pod-perii-vadului-2.jpg",
      },
    ],
  },
  {
    id: "5",
    denumire: "pod Cluj Arena fara izo",
    titlu:
      "Poduri din Cluj-Napoca, lângă Cluj Arena şi peste linia de tramvai, la care nu s-a utilizat hidroizolaţia IZO-35.",
    observatii: [
      {
        id: "1",
        observatie:
          "Costurile pentru lucrările de întreţinere şi reparaţii sunt de zeci de ori mai mari, comparativ cu podurile la care s-a utilizat hidroizolaţie performantă IZO-35. Grinzile sunt crăpate şi armăturile sunt ruginite şi rupte.",
      },
      {
        id: "2",
        observatie:
          "Aplicarea pe poduri a hidroizolaţiilor ieftine, se va materializa ulterior prin costuri foarte mari pentru reparaţii.",
      },
    ],
    galleryImg: [
      {
        id: "1",
        src: "/images/Portofoliu/PodClujArenaFaraIzo/pod-cluj-arena-fara-izo-1.jpg",
      },
      {
        id: "2",
        src: "/images/Portofoliu/PodClujArenaFaraIzo/pod-cluj-arena-fara-izo-2.jpg",
      },
    ],
  },
];
