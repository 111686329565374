import React from "react";
import styled from "styled-components";
import DivObservatiiLucrareSingulara from "./DivObservatiiLucrareSingulara";

const ContainerHeaderLucrareSingulara = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Titlu = styled.div`
  font-weight: 700;
  width: 90%;
  color: rgba(0, 114, 188, 206);
  text-decoration: underline;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1000px) {
    width: 95%;
    text-align: center;
  }
`;

const HeaderLucrareSingulara = ({ lucrare }) => {
  return (
    <ContainerHeaderLucrareSingulara>
      <Titlu>{lucrare.titlu}</Titlu>
      <DivObservatiiLucrareSingulara lucrare={lucrare} />
    </ContainerHeaderLucrareSingulara>
  );
};

export default HeaderLucrareSingulara;
