import React from "react";
import styled from "styled-components";
import ImageGridWithModal from "../ImageGridWithModal";

const ContainerTextSiGalerieImg = styled.div`
  //border: 2px solid red;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  width: 60%;
  @media screen and (max-width: 1000px) {
    width: 90%;
  }
`;
const Text = styled.ul`
  //border: 2px solid blue;
  color: rgba(0, 114, 188, 206);
  text-decoration: underline;
  width: 100%;
  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`;

const DivTextSiGalerieImg = ({ itemDateTesteLaborator }) => {
  return (
    <ContainerTextSiGalerieImg>
      <Text>
        {itemDateTesteLaborator.paragrafeText.map((paragraf) => {
          return <li key={paragraf.id}>{paragraf.text}</li>;
        })}
      </Text>
      <ImageGridWithModal img={itemDateTesteLaborator.galleryImg} />
    </ContainerTextSiGalerieImg>
  );
};

export default DivTextSiGalerieImg;
