import React from "react";
import styled from "styled-components";
import ImageGridWithModal from "../../ImageGridWithModal";
import HeaderLucrareSingulara from "./HeaderLucrareSingulara";

const ContainerLucrareSngulara = styled.div`
  //border: 2px solid red;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  @media screen and (max-width: 1400px) {
    width: 90%;
  }
`;

const DivLucrareSingulara = ({ lucrare }) => {
  return (
    <ContainerLucrareSngulara>
      <HeaderLucrareSingulara lucrare={lucrare} />
      <ImageGridWithModal
        img={lucrare.galleryImg}
        dimensiuneImgDesktop="400px"
        dimensiuneImgMobil="350px"
      />
    </ContainerLucrareSngulara>
  );
};

export default DivLucrareSingulara;
