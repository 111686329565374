import React from "react";
import styled from "styled-components";
import * as variabileDesign from "../VariabileDesign/variabileDesign.js";

const ContainerPrezentareGenerala = styled.div`
  width: 100%;
  //border: 2px solid red;
  margin-top: 30px;
  font-size: 15px;
`;

const DivImgTextPrezentareGenerala = styled.div`
  //border: 2px solid blue;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;
const DivImg = styled.div`
  //border: 0.2px solid red;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px 0 20px;
`;
const ImgIzo = styled.img`
  border: 0.2px solid black;
  width: 100%;

  // @media screen and (max-width: 1300px) {
  //   width: 300px;
  // }

  @media screen and (max-width: 1300px) {
    width: 300px;
  }
  @media screen and (max-width: 1000px) {
    width: 200px;
  }
`;

const TitlePrezentareGenerala = styled.div`
  font-weight: 700;
  font-size: 25px;
  text-align: center;
  margin-top: 20px;
  color: ${variabileDesign.culoareTextPrimar};
  @media screen and (max-width: 1300px) {
    margin-top: 10px;
    margin-bottom: 20px;
  }
`;
const TextPrezentareGenerala = styled.div`
  // /border: 2px solid yellow;
  padding: 20px;
`;

const PrezentareGenerala = () => {
  return (
    <ContainerPrezentareGenerala>
      <TitlePrezentareGenerala>Prezentare generală</TitlePrezentareGenerala>
      <DivImgTextPrezentareGenerala>
        <DivImg>
          <ImgIzo src="/images/HomePage/izo-35-v3.png" />
        </DivImg>
        <TextPrezentareGenerala>
          <div>
            <span style={{ fontWeight: "600" }}>
              Sistemul hidroizolant IZO-35, Marca Tomizo
            </span>
            , este produs în România, având la bază brevetul de invenţie Nr.
            132426, cu titlul “Compoziţie de cauciuc pentru folie hidroizolantă,
            procedeu de obţinere şi metodă de utilizare” a domnului inventator
            Tomina Vasile-Florin, înregistrat la OSIM.
          </div>
          <div>
            Membrana hidroizolantă utilizată în prezentul agrement este produsă
            de ARTEGO S.A. Târgu Jiu, iar adezivii din prezentul agrement se
            produc la EUROPLASTIC S.R.L. Bucureşti şi MAPEI.
          </div>
          <div>
            <div>
              Sistemului hidroizolant IZO-35 este o versiune mult mai
              performantă a sistemului hidroizolant Hidroizoflex (brevetat de
              acelaşi inventator), care s-a aplicat în anii 2001-2002 la mai
              multe lucrări de poduri, dintre care menţionăm doar:
            </div>
            <ul>
              <li>pod pe DN 75, la Lungeşti (la 28 km de Turda);</li>
              <li>
                pod pe DN 1C, la Buneşti (la 48 km de Cluj, drumul european
                E576);
              </li>
              <li>
                pod pe DN 1C, km 125, din localitatea Perii Vadului, judeţul
                Sălaj (drumul european E58).
              </li>
            </ul>
          </div>
          <div>
            Toate podurile hidroizolate în anii 2001-2002 cu compoziţia de
            cauciuc din prezentul agrement, prezintă un intrados al
            suprastructurii fără infiltraţii de umezeală semnificative şi fără
            afectarea stratului de acoperire cu beton a armăturilor,
            încadrându-se la peste 20 de ani de la aplicarea hidroizolaţiei în
            Clasa stării tehnice II – Stare bună, conform AND 522-2002.
          </div>
        </TextPrezentareGenerala>
      </DivImgTextPrezentareGenerala>
    </ContainerPrezentareGenerala>
  );
};

export default PrezentareGenerala;
