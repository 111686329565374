import React from "react";
import styled from "styled-components";
import DivLucrareSingulara from "./DivLucrareSingulara/DivLucrareSingulara";
import { lucrari } from "../Data/lucrari";
import * as variabileDesign from "../VariabileDesign/variabileDesign.js";

const ContainerPortofoliu = styled.div`
  //border: 2px solid red;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const ContentPortofoliu = styled.div`
  //border: 2px solid blue;
  //width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`;
const DivTitluSiSubtitlu = styled.div`
  //border: 2px solid red;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const TitluPortofoliu = styled.div`
  //border: 2px solid green;
  font-weight: 700;
  font-size: 20px;
  margin-top: 30px;
  text-align: center;
  color: ${variabileDesign.culoareTextPrimar};

  @media screen and (max-width: 1000px) {
    width: 95%;
  }
`;
const SubtitluPortofoliu = styled.div`
  // /border: 2px solid green;
  width: 70%;
  font-weight: 600;
  font-size: 18px;
  margin-top: 20px;
  text-align: center;
  @media screen and (max-width: 1000px) {
    font-size: 16px;
    margin-top: 20px;
    width: 95%;
  }
`;
const DivLucrari = styled.div`
  //border: 2px solid red;
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`;

const PortofoliuLucrari = () => {
  return (
    <ContainerPortofoliu>
      <div id="portofoliu-lucrari"></div>
      <ContentPortofoliu>
        <DivTitluSiSubtitlu>
          <TitluPortofoliu>
            Portofoliu de lucrări şi poze de la poduri, prezentate comparativ
          </TitluPortofoliu>
          <SubtitluPortofoliu>
            Pentru a prezenta avantajele utilizării sistemului hidroizolant
            IZO-35, se vor prezenta comparativ poze de la lucrările la care s-a
            utilizat compoziţia de cauciuc utilizată la IZO-35, în paralel cu
            poze de la alte lucrări de poduri, la care nu s-a utilizat sistemul
            hidroizolant IZO-35.
          </SubtitluPortofoliu>
        </DivTitluSiSubtitlu>
        <DivLucrari>
          {lucrari.map((lucrare) => {
            return <DivLucrareSingulara key={lucrare.id} lucrare={lucrare} />;
          })}
        </DivLucrari>
      </ContentPortofoliu>
    </ContainerPortofoliu>
  );
};

export default PortofoliuLucrari;
