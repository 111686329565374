export const imaginiAgrement = [
  {
    id: "1",
    src: "/images/ConformitateSiAgrement/ImaginiAgrement/1.jpg",
  },
  {
    id: "1",
    src: "/images/ConformitateSiAgrement/ImaginiAgrement/2.jpg",
  },
  {
    id: "1",
    src: "/images/ConformitateSiAgrement/ImaginiAgrement/3.jpg",
  },
  {
    id: "1",
    src: "/images/ConformitateSiAgrement/ImaginiAgrement/4.jpg",
  },
  {
    id: "1",
    src: "/images/ConformitateSiAgrement/ImaginiAgrement/5.jpg",
  },
  {
    id: "1",
    src: "/images/ConformitateSiAgrement/ImaginiAgrement/6.jpg",
  },
  {
    id: "1",
    src: "/images/ConformitateSiAgrement/ImaginiAgrement/7.jpg",
  },
  {
    id: "1",
    src: "/images/ConformitateSiAgrement/ImaginiAgrement/8.jpg",
  },
  {
    id: "1",
    src: "/images/ConformitateSiAgrement/ImaginiAgrement/9.jpg",
  },
  {
    id: "1",
    src: "/images/ConformitateSiAgrement/ImaginiAgrement/10.jpg",
  },
  {
    id: "1",
    src: "/images/ConformitateSiAgrement/ImaginiAgrement/11.jpg",
  },
  {
    id: "1",
    src: "/images/ConformitateSiAgrement/ImaginiAgrement/12.jpg",
  },
  {
    id: "1",
    src: "/images/ConformitateSiAgrement/ImaginiAgrement/13.jpg",
  },
  {
    id: "1",
    src: "/images/ConformitateSiAgrement/ImaginiAgrement/14.jpg",
  },
  {
    id: "1",
    src: "/images/ConformitateSiAgrement/ImaginiAgrement/15.jpg",
  },
  {
    id: "1",
    src: "/images/ConformitateSiAgrement/ImaginiAgrement/16.jpg",
  },
  {
    id: "1",
    src: "/images/ConformitateSiAgrement/ImaginiAgrement/17.jpg",
  },
  {
    id: "1",
    src: "/images/ConformitateSiAgrement/ImaginiAgrement/18.jpg",
  },
  {
    id: "1",
    src: "/images/ConformitateSiAgrement/ImaginiAgrement/19.jpg",
  },
  {
    id: "1",
    src: "/images/ConformitateSiAgrement/ImaginiAgrement/20.jpg",
  },
  {
    id: "1",
    src: "/images/ConformitateSiAgrement/ImaginiAgrement/21.jpg",
  },
  {
    id: "1",
    src: "/images/ConformitateSiAgrement/ImaginiAgrement/22.jpg",
  },
  {
    id: "1",
    src: "/images/ConformitateSiAgrement/ImaginiAgrement/23.jpg",
  },
  {
    id: "1",
    src: "/images/ConformitateSiAgrement/ImaginiAgrement/24.jpg",
  },
  {
    id: "1",
    src: "/images/ConformitateSiAgrement/ImaginiAgrement/25.jpg",
  },
  {
    id: "1",
    src: "/images/ConformitateSiAgrement/ImaginiAgrement/26.jpg",
  },
  {
    id: "1",
    src: "/images/ConformitateSiAgrement/ImaginiAgrement/27.jpg",
  },
  {
    id: "1",
    src: "/images/ConformitateSiAgrement/ImaginiAgrement/28.jpg",
  },
  {
    id: "1",
    src: "/images/ConformitateSiAgrement/ImaginiAgrement/29.jpg",
  },
];
