import React from "react";
import styled from "styled-components";
import HambourgerIcon from "../HambourgerIcon.js";
import * as variabileDesign from "../VariabileDesign/variabileDesign.js";
import LogoTomizo from "../Logos/LogoTomizo.js";
import SideNav from "../SideNav.js";

const HeaderDesktopScroll = styled.div`
  // /border: 2px solid red;
  width: 100%;
  height: 80px;
  background-color: rgba(30, 85, 130, 0.8);
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  @media screen and (max-width: 1100px) {
    display: none;
  }
`;

const HeaderDesktopAtScroll = ({ sideNav, showSideNav }) => {
  return (
    <HeaderDesktopScroll>
      <LogoTomizo width="60px" position="absolute" left="75px" />
      {!sideNav && (
        <HambourgerIcon
          showSideNav={showSideNav}
          color="white"
          fontSize="45px"
          right="75px"
        />
      )}
    </HeaderDesktopScroll>
  );
};

export default HeaderDesktopAtScroll;
