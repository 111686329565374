import React from "react";
import styled from "styled-components";
import DivFig1 from "./DivFig1";

const DivTextFig1 = styled.div`
  //border: 1px solid black;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1500px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`;
const TextAlcatuireHidroizolatie = styled.div`
  //border: 1px solid blue;
  font-size: 15px;
  width: 40%;
  padding: 20px;
  @media screen and (max-width: 1500px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70%;
  }
  @media screen and (max-width: 1000px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
`;

const DivAlcatuireIzo = () => {
  return (
    <DivTextFig1>
      <TextAlcatuireHidroizolatie>
        <div>
          <div>
            Sistemul hidroizolator IZO-35, Marca Tomizo, este alcătuit din 4
            straturi:
          </div>
          <div>
            <ul>
              <li>
                (A) Strat cu rol de amorsă, barieră de vapori, colmatarea
                fisurilor din stratul suport
              </li>
              <li>(B) Strat de adeziv</li>
              <li>(C) Membrană hidroizolantă din cauciuc armat</li>
              <li>(D) Strat de protecţie a membranei hidroizolante</li>
            </ul>
          </div>
          <div>
            având alcătuirea conform Figurii 1, cu o aplicare pe stratul suport,
            conform următoarelor scheme de dispunere şi procese tehnologice:
          </div>
        </div>
      </TextAlcatuireHidroizolatie>
      <DivFig1 />
    </DivTextFig1>
  );
};

export default DivAlcatuireIzo;
