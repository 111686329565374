import React from "react";
import styled from "styled-components";
import * as variabileDesign from "../VariabileDesign/variabileDesign.js";

const ContainerDomeniiUtilizare = styled.div`
  //border: 2px solid red;
  width: 100%;
  margin-top: 50px;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1220px) {
    margin-top: 0;
  }
`;
const TitleDomeniiUtilizare = styled.div`
  font-weight: 700;
  font-size: 25px;
  text-align: center;
  color: ${variabileDesign.culoareTextPrimar};
`;
const DivTextImgDomeniiUtilizare = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;
const DivTitluTextDomeniiUtilizare = styled.div`
  //border: 2px solid red;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const TextDomeniiUtilizare = styled.div`
  padding: 20px;
  font-size: 15px;
`;
const DivImg = styled.div`
  width: 100%;
  //border: 0.2px solid red;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px 0 20px;
`;
const ImgDomeniiUtilizare = styled.img`
  //border: 2px solid red;
  width: 100%;
  // padding: 20px;
  @media screen and (max-width: 1000px) {
    width: 40%;
    //padding: 0 20px 20px 20px;
  }
  @media screen and (max-width: 650px) {
    width: 80%;
    // padding: 0 20px 20px 20px;
  }
`;

const DomeniiDeUtilizare = () => {
  return (
    <ContainerDomeniiUtilizare>
      <DivTextImgDomeniiUtilizare>
        <DivTitluTextDomeniiUtilizare>
          <TitleDomeniiUtilizare>Domenii de utilizare</TitleDomeniiUtilizare>
          <TextDomeniiUtilizare>
            <div>
              Sistemul hidroizolator IZO-35, Marca Tomizo, a fost proiectat
              pentru a realiza protecţia împotriva apei la lucrările de
              infrastructură de tipul: poduri, pasaje, viaducte, podețe,
              tuneluri, stații de metrou, aeroporturi, dar poate fi aplicat şi
              la alte tipuri de lucrări de infrastructură de tipul parcări,
              platforme, ziduri de sprijin, lucrări hidrotehnice, bazine, etc. ,
              conform: Agrement Tehnic 001SC-07/065-2023.
            </div>
            <div>
              În urma unor teste prealabile, care să corespundă cerinţelor
              beneficiarului, sistemul hidroizolant IZO-35, poate fi utilizat şi
              pentru alte tipuri construcţii, cu condiţia ca cerinţele la
              solicitările statice, dinamice, variaţiile de temperatură şi
              umiditate, să se încadreze în limitele de exploatare ale
              hidroizolaţiilor utilizate la poduri.
            </div>
          </TextDomeniiUtilizare>
        </DivTitluTextDomeniiUtilizare>
        <DivImg>
          <ImgDomeniiUtilizare src="/images/HomePage/colaj-utilizare-v2.png" />
        </DivImg>
      </DivTextImgDomeniiUtilizare>
    </ContainerDomeniiUtilizare>
  );
};

export default DomeniiDeUtilizare;
